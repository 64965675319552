<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="openModal()"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Activity</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
              @updatePosDown="updatePosDown"
              @updatePosUp="updatePosUp"
              @onEdit="openModal"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <ActivitiesModal
      v-if="modalOpen"
      :item="selectedItem"
      @saved="$refs.vtable.init"
      @close="closeModal"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ActivitiesModal from './ActivitiesModal.vue';

export default {
  components: {
    VueTable,
    ActivitiesModal,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'activity_id',
            mask: 'ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'date',
            dateFormat: true,
            sortable: true,
          },
          {
            mask: 'unenroll limit',
            title: 'unenroll_limit_date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'address',
            sortable: true,
          },
          {
            title: 'enroll_limit',
            mask: 'enroll limit',
            sortable: true,
          },
          {
            title: 'activity_users',
            mask: 'enrolled',
            callback: (item) => item.length,
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Update Position Down',
            callback: 'updatePosDown',
            icon: 'expand_more',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Update Position Up',
            callback: 'updatePosUp',
            icon: 'expand_less',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Activity',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Activity',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      selectedItem: null,
      modalOpen: false,
      loading: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.request('app/activities', 'get', params, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    openModal(item) {
      if (item) this.selectedItem = item;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.selectedItem = null;
    },
    onDelete(item) {
      this.request(`app/activities/${item.activity_id}`, 'delete', null, ({ data }) => {
        this.fireSuccess(data);
        this.$refs.vtable.init();
      });
    },
    updatePosUp(item) {
      this.updatePosition(item, -1);
    },
    updatePosDown(item) {
      this.updatePosition(item, +1);
    },
    // eslint-disable-next-line camelcase
    updatePosition(item, position_modifier) {
      this.loading = true;
      this.request(`app/activities/${item.activity_id}/position`, 'put', { position_modifier }, () => {
        this.fireSuccess('Position updated succesfully!');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style scoped>
</style>
