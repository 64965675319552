<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="$emit('close')"
  >
    <template slot="title">
      Activity Form
    </template>
    <template slot="body">
      <FormText
        v-model="form.title"
        label="Title"
        icon="description"
      />
      <FormDate
        v-model="form.date"
        label="Date"
      />
      <FormDate
        v-model="form.unenroll_limit_date"
        label="Unenroll Limit Date"
      />
      <FormNumber
        v-model="form.enroll_limit"
        icon="tag"
        label="Enroll Limit"
      />

      <div class="label">
        <md-icon>description</md-icon>
        <label for="">Body</label>
      </div>
      <trumbowyg
        v-model="form.body"
        :config="config"
        class="form-control"
        name="resultHtml"
      />
      <FormText
        v-model="form.address"
        label="Address"
        icon="my_location"
      />
      <div class="d-flex">
        <FormText
          v-model="form.map_url"
          :value="form.map_url"
          icon="place"
          label="Map Url"
          class="flex-1"
          @input="onChangeMapUrl"
        />
        <md-button
          class="md-just-icon md-simple md-sm md-info mt-2"
          @click="showMapDetails"
        >
          <md-icon>info</md-icon>
        </md-button>
      </div>
      <iframe
        v-if="form.map_url"
        :src="form.map_url"
        width="100%"
        height="300"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />

      <b class="d-block mt-3">Thumbnail Image</b>
      <div class="photo-grid">
        <div
          v-if="form.thumbnail_image"
          class="img-container"
          :style="`background-image: url('${form.thumbnail_image.image_url}')`"
        >
          <div class="buttons-overlay">
            <md-button
              class="md-just-icon md-sm md-danger md-round"
              @click="removeThumbnailImage"
            >
              <md-icon class="m-0">
                delete
              </md-icon>
            </md-button>
          </div>
        </div>
        <FormUploader
          v-else
          class="image-uploader"
          :max-size="1024*20"
          @input="thumbnailImageSelected"
          @error="fireError"
        >
          <div class="image-button">
            <md-icon>photo_camera</md-icon>
            <span>Click to upload Image</span>
          </div>
        </FormUploader>
      </div>

      <b class="d-block mt-3">Images</b>
      <small>
        To Upload Videos you can convert it to GIF first here:
        <a
          href="https://convertio.co/"
          target="_blank"
        >https://convertio.co/</a>
      </small>
      <div class="photo-grid">
        <div
          v-for="(image, index) of form.images"
          :key="image.key"
          class="img-container"
          :style="`background-image: url('${image.image_url}')`"
        >
          <div class="buttons-overlay">
            <md-button
              class="md-just-icon md-sm md-danger md-round"
              @click="removeImage(index, false)"
            >
              <md-icon class="m-0">
                delete
              </md-icon>
            </md-button>
          </div>
        </div>
        <FormUploader
          class="image-uploader"
          :max-size="1024*20"
          @input="imageSelected($event, false)"
          @error="fireError"
        >
          <div class="image-button">
            <md-icon>photo_camera</md-icon>
            <span>Click to upload Image</span>
          </div>
        </FormUploader>
      </div>

      <template v-if="form.activity_id">
        <b class="d-block mt-3">
          Gallery Images
        </b>
        <div class="photo-grid">
          <div
            v-for="(image, index) of form.gallery_images"
            :key="image.key"
            class="img-container"
            :style="`background-image: url('${image.image_url}')`"
          >
            <div class="buttons-overlay">
              <md-button
                class="md-just-icon md-sm md-danger md-round"
                @click="removeImage(index, true)"
              >
                <md-icon class="m-0">
                  delete
                </md-icon>
              </md-button>
            </div>
          </div>
          <FormUploader
            class="image-uploader"
            :max-size="1024*20"
            @input="imageSelected($event, true)"
            @error="fireError"
          >
            <div class="image-button">
              <md-icon>photo_camera</md-icon>
              <span>Click to upload Gallery Image</span>
            </div>
          </FormUploader>
        </div>
      </template>

      <template v-if="form.activity_id && form.activity_users.length">
        <div class="d-flex">
          <b class="d-block mt-3">
            Enrollments
          </b>
          <md-button
            class="md-primary md-round ml-auto"
            @click="downloadXls"
          >
            <md-icon>download</md-icon>
            <span class="ml-2">Download XLS</span>
          </md-button>
        </div>
        <md-table class="table">
          <md-table-row>
            <md-table-head>Student Number</md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head>Email</md-table-head>
            <md-table-head>Phone</md-table-head>
            <md-table-head>Enrolled At</md-table-head>
          </md-table-row>
          <md-table-row
            v-for="auser of form.activity_users"
            :key="auser.user_id"
          >
            <md-table-cell>{{ auser.user.student_number }}</md-table-cell>
            <md-table-cell>{{ auser.student.full_name }}</md-table-cell>
            <md-table-cell>{{ auser.student.email }}</md-table-cell>
            <md-table-cell>{{ auser.student.phone }}</md-table-cell>
            <md-table-cell>{{ dateTimeFormat(auser.created_at) }}</md-table-cell>
          </md-table-row>
        </md-table>
      </template>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary md-sm"
        @click="save"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText, FormDate, FormUploader, FormNumber,
} from '@/components/Inputs';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    FormText, FormDate, FormUploader, FormNumber,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      date: null,
      body: null,
      address: null,
      map_url: null,
      enroll_limit: 10,
      unenroll_limit_date: null,
      images: [],
      gallery_images: [],
      thumbnail_image: null,
    },
    config: {
      btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
      ],
      semantic: {
        div: 'div', // Editor does nothing on div tags now
      },
    },
    loading: false,
  }),
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },

    /**
     * If activity already exists,
     * upload and save the image.
     * If not, just add the image to the array.
     */
    imageSelected(imageFile, is_gallery = false) {
      const imageURL = URL.createObjectURL(imageFile);

      if (this.form.activity_id) {
        this.loading = true;
        const fd = new FormData();
        fd.append('image', imageFile);
        fd.append('is_gallery', is_gallery);

        this.request(
          `app/activities/${this.form.activity_id}/images`,
          'post',
          fd,
          ({ data }) => {
            // eslint-disable-next-line camelcase
            this.form[is_gallery ? 'gallery_images' : 'images'].push({
              ...data,
              key: Math.floor(Math.random() * Date.now()),
            });
          }, () => {
            this.$emit('saved');
            this.loading = false;
          },
        );
      } else {
        // eslint-disable-next-line camelcase
        this.form[is_gallery ? 'gallery_images' : 'images'].push({
          key: Math.floor(Math.random() * Date.now()),
          file: imageFile,
          image_url: imageURL,
        });
      }
    },

    thumbnailImageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);

      if (this.form.activity_id) {
        this.loading = true;
        const fd = new FormData();
        fd.append('image', imageFile);
        fd.append('is_thumbnail', true);

        this.request(
          `app/activities/${this.form.activity_id}/images`,
          'post',
          fd,
          ({ data }) => {
            // eslint-disable-next-line camelcase
            this.form.thumbnail_image = data;
          }, () => {
            this.loading = false;
            this.$emit('saved');
          },
        );
      } else {
        this.form.thumbnail_image = {
          file: imageFile,
          image_url: imageURL,
        };
      }
    },
    removeThumbnailImage() {
      if (this.form.thumbnail_image.activity_image_id) {
        this.fireConfirm('Delete Image', 'You will permanently lose this picture. \n Are you sure?')
          .then(() => {
            this.loading = true;
            this.request(
              `app/activities/${this.form.activity_id}/images/${this.form.thumbnail_image.activity_image_id}`,
              'delete',
              null,
              () => {
                this.fireSuccess('Thumbnail Deleted');
                this.form.thumbnail_image = null;
                this.$emit('saved');
              }, () => {
                this.loading = false;
              },
            );
          });
      } else {
        this.form.thumbnail_image = null;
      }
    },

    /**
     * If activity already exists,
     * send request to delete the image.
     * If not, just remove the image from the array.
     */
    removeImage(index, gallery = false) {
      if (this.form[gallery ? 'gallery_images' : 'images'][index].activity_image_id) {
        this.fireConfirm('Delete Image', 'You will permanently lose this picture. \n Are you sure?')
          .then(() => {
            this.loading = true;
            this.request(
              `app/activities/${this.form.activity_id}/images/${this.form[gallery ? 'gallery_images' : 'images'][index].activity_image_id}`,
              'delete',
              null,
              ({ data }) => {
                this.fireSuccess(data);
                this.form[gallery ? 'gallery_images' : 'images'].splice(index, 1);
                this.$emit('saved');
              }, () => {
                this.loading = false;
              },
            );
          });
      } else {
        this.form[gallery ? 'gallery_images' : 'images'].splice(index, 1);
      }
    },

    /**
     * If activity already exists,
     * send request to update the activity without images.
     * If not, add images to the request.
     */
    save() {
      this.loading = true;

      if (this.form.activity_id) {
        this.request(`app/activities/${this.form.activity_id}`, 'put', this.form, ({ data }) => {
          this.fireSuccess(data);
          this.$emit('saved');
          this.close();
        }, () => {
          this.loading = false;
        });
      } else {
        const fd = new FormData();
        fd.append('title', this.form.title);
        fd.append('date', this.form.date);
        fd.append('body', this.form.body);
        fd.append('address', this.form.address);
        fd.append('enroll_limit', this.form.enroll_limit);
        fd.append('unenroll_limit_date', this.form.unenroll_limit_date);
        fd.append('map_url', this.form.map_url);
        if (this.form.thumbnail_image?.file) {
          fd.append('thumbnail_image', this.form.thumbnail_image.file);
        }
        this.form.images.filter((x) => x.file).forEach((x) => fd.append('images', x.file));

        this.request('app/activities', 'post', fd, ({ data }) => {
          this.fireSuccess(data);
          this.$emit('saved');
          this.close();
        }, () => {
          this.loading = false;
        });
      }
    },

    /**
     * Script to extract MapUrl from google iframe
     */
    onChangeMapUrl(string) {
      if (!string || string.slice(0, 4) === 'http') return;

      const element = document.createElement('div');
      element.innerHTML = string;
      this.form.map_url = element.firstChild.src;
    },

    /**
     * Download Enrolments XLS
     */
    downloadXls() {
      this.loading = true;
      this.request(`app/activities/${this.item.activity_id}/xls`, 'download', {}, ({ data }) => {
        const filename = `${this.item.title.toLowerCase().trim().split(' ').join('-')}_enrolments.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },

    showMapDetails() {
      this.fireModal({
        title: 'Map URL Details',
        html: `
          <div class="text-left">
            To obtain the Map URL follow these steps: <br>
            <ul>
              <li>
                Go To Google Maps: 
                <a href="https://www.google.com/maps" target="_blank">https://www.google.com/maps</a>
              </li>
              <li>
                Find your location and click on it.
              </li>
              <li>
                Click on Share button & Select "embed a map"
              </li>
              <li>
                Copy & paste content into Map Url input.
              </li>
            </ul>
          </div>
        `,
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .md-table .md-table-head {
  padding-bottom: .5em;
}
.label {
  margin-top: .5em;
  .md-icon {
    color: #495057 !important;
  }
  label {
    font-size: 0.6875rem;
    margin-left: 1.15em;
    color: rgba(0, 0, 0, 0.54)  !important
  }
}
::v-deep .trumbowyg-box.trumbowyg {
  margin-top: .5em !important;
  margin-bottom: 2em;
}
</style>
